@import '../../variables.scss';

.attendeeSearch {
    width: 100%;
    padding: vw(20) 0;
    padding-bottom: 0;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    header {
        font-style: normal;
        font-weight: 700;
        font-size: vw(16);
        line-height: vw(20);
        color: $blue;
        margin-bottom: vw(20);
    }

    .inputWrapper {
        padding: 0 vw(20);
        display: flex;
        flex-direction: column;
        gap: vw(20);
        margin-bottom: vw(40);
    }

    .inputWrapper2 {
        padding: 0 vw(20);
        display: flex;
        flex-direction: column;
        gap: vw(20);
        margin-bottom: vw(10);
    }

    .search {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}